// Example experiment do not remove

export default {
  // Name of the experiment
  name: "workThere",

  // Google optimize experiment id
  id: "ZFHlzBkWQk6NK81hJZoUHw",

  enabled: true,
  /*
   * if set to true, devVariant will be forced onto the user
   * this way we can force a specific variant for testing forexample
   */
  devEnabled: true,
  // Variant to forcibly show in dev env
  devVariant: 1,

  // [optional] specify number of sections for MVT experiments
  // sections: 1,

  // [optional] Enable/Set experiment on certain conditions
  // isEligible: ({ route }) => route.path !== '/foo'

  // Implemented variants and their weights
  variants: [1, 1],
};

// Primary: Clicked tender request

// action 'kattintas-ajanlatkeresre'

// category kivenni filterbol

// 3 varians (catergory), kartyan, lebegon, gyorsitottan

// Secodnary: Clicked person card
