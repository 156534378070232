<template>
  <JoszakiButton v-bind="$attrs" class="relative">
    <input
      class="absolute opacity-0 top-0 right-0 left-0 bottom-0 cursor-pointer"
      type="file"
      :multiple="allowMultiple"
      :accept="accept"
      @change="changeHandler"
    />
    {{ label }}
  </JoszakiButton>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeHandler(event) {
      const files = event.target.files;
      this.$emit("upload", files);
    },
  },
};
</script>
