export const state = () => {
  return {
    city: { name: "Ország szerte", seoName: "" },
    searchQuery: "",
    loading: false,
    loaded: false,
    success: false,
  };
};

export const getters = {
  city: (state) => state.city,
  loading: (state) => state.loading,
  loaded: (state) => state.loaded,
  searchQuery: (state) => state.searchQuery,
};

export const mutations = {
  setCity(state, city) {
    state.city = city;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setLoaded(state, payload) {
    state.loaded = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setSearchQuery(state, payload) {
    state.searchQuery = payload;
  },
};

export const actions = {
  setSearchQuery(context, payload) {
    context.commit("setSearchQuery", payload);
  },
  setCity(context, payload) {
    context.commit("setCity", payload);
  },
  setLoading(context, payload) {
    context.commit("setLoading", payload);
  },
  setLoaded(context, payload) {
    context.commit("setLoaded", payload);
  },
  setSuccess(context, payload) {
    context.commit("setSuccess", payload);
  },

  async fetchCityByIp(context) {
    console.info("fetchCityByIp");
    context.commit("setLoading", true);

    try {
      if (this.$ab.isVariant("city-by-ip", "test")) {
        const CITY_BY_IP = await import("./cityByIp.graphql");
        const result = await this.$query(CITY_BY_IP);
        if (result.city) {
          const cityName = result.city;
          const foundCity = context.rootGetters["data/cities"].find(
            (cityObject) => {
              return cityObject.name === cityName;
            }
          );
          if (foundCity) {
            context.commit("setCity", foundCity);
            context.commit("setSuccess", true);
          } else {
            context.commit("setSuccess", false);
            console.error("Cant find fetched city");
          }
        }
      } else {
        fetch("https://pro.ip-api.com/json/?fields=61439&key=tciGrMi0wHfhxio")
          .then((res) => res.json())
          .then((result) => {
            context.commit("setLoading", false);
            const cityName = result.city;
            const foundCity = context.rootGetters["data/cities"].find(
              (cityObject) => {
                return cityObject.name === cityName;
              }
            );

            if (foundCity) {
              context.commit("setCity", foundCity);
              context.commit("setSuccess", true);
            } else {
              context.commit("setSuccess", false);
              console.error("Cant find fetched city");
            }
          });
      }
    } catch (error) {
      console.error("Cant fetch city", error);
      context.commit("setSuccess", false);
    } finally {
      context.commit("setLoaded", true);
      context.commit("setLoading", false);
    }
  },
};
