<template>
  <NuxtLink
    class="bg-white border-solid border-[#EFEFEF] hover:border-primary-lighter focus:border-primary-lighter border rounded-md flex items-center justify-center h-11 w-11"
    :class="{
      'bg-[#50c0ff1a] cursor-auto pointer-events-none border-[#50C0FF]': active,
      'hover:border-border-[#EFEFEF] cursor-auto pointer-events-none': disabled,
    }"
    :to="url"
    @click.native="linkClicked"
  >
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    url: {
      type: [Object, String],
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    linkClicked(e) {
      if (this.disabled) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      this.$emit("click");
    },
  },
};
</script>
