import Vue from "vue";

export default () => {
  Vue.filter("currency", function (value, currency = "") {
    const stringReverse = (str) => {
      return str.split("").reverse().join("");
    };
    if (!value) {
      return "";
    }
    if (value.constructor !== Number && value.constructor !== String) {
      return value;
    }
    value = value.toString();
    value = stringReverse(value);
    value = value.match(/.{1,3}/g).join(" ");
    value = stringReverse(value);
    return value + " " + currency;
  });

  Vue.filter("phone", function (value) {
    if (!value) {
      return "";
    }
    if (value.constructor !== Number && value.constructor !== String) {
      return value;
    }
    value = value.toString();
    value = value
      .match(/(\d{0,2})(\d{0,2})(\d{0,3})(\d{0,2})/)
      .slice(1)
      .join(" ");
    return value;
  });
};
