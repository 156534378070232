<template>
  <b-autocomplete
    ref="cityAutocomplete"
    v-model="cityFilter"
    class="autocomplete-city"
    icon-pack="fas"
    :icon="icon"
    v-bind="$attrs"
    expanded
    :open-on-focus="openOnFocus"
    icon-right-clickable
    icon-right="angle-down"
    field="name"
    :data="filteredCities"
    :disabled="!isClientReady"
    :keep-first="keepFirst"
    :placeholder="placeholder"
    @focus="onFocus"
    @select="select"
    @icon-right-click.stop="blur"
  >
    <template #empty>
      {{ $t("cityAutocomplete.empty") }}
    </template>
  </b-autocomplete>
</template>

<script>
import { debounce } from "~/helpers/debounce";

export default {
  props: {
    prefill: {
      type: Object,
      default: null,
    },
    keepFirst: {
      type: Boolean,
      default: true,
    },
    filterCapitol: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "map-marker-alt",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    typingTrackerEvent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cityFilter: "",
      openOnFocus: true,
    };
  },
  computed: {
    isClientReady() {
      return process.client;
    },
    cities() {
      return this.$store.getters["data/cities"];
    },
    normalizedCityFilter() {
      return this.$helpers.normalizeInput(this.cityFilter);
    },
    filteredCities() {
      let result = [];
      if (this.cities) {
        const rankedList = [];
        for (const city of this.cities) {
          if (this.filterCapitol && city.id === "2") {
            continue;
          }
          const index = this.$helpers
            .normalizeInput(city.name)
            .indexOf(this.normalizedCityFilter);
          if (index > -1) {
            rankedList.push({
              index,
              city,
            });
          }
        }
        rankedList.sort((a, b) => {
          if (a.index === b.index) {
            if (a.city.parentCityId && b.city.parentCityId) {
              return a.city.id > b.city.id ? 1 : -1;
            }
            return a.city.name > b.city.name ? 1 : -1;
          }
          return a.index > b.index ? 1 : -1;
        });
        result = rankedList.map((x) => x.city);
      }
      return result;
    },
  },
  watch: {
    cityFilter(...args) {
      if (this.typingTrackerEvent) {
        this.debouncedTrackTyping(...args);
      }
    },
  },
  mounted() {
    this.setSelected(this.prefill);
    if (this.autofocus) {
      this.focusInput();
    }

    if (this.typingTrackerEvent) {
      this.debouncedTrackTyping = debounce((newValue, _oldValue) => {
        this.$trackers.trackEvent({
          title: this.typingTrackerEvent,
          data: { value: newValue },
        });
      }, 500);
    }
  },
  methods: {
    focusInput() {
      try {
        this.$refs.cityAutocomplete.focus();
      } catch (error) {
        console.info("Cant auto focus cityAutocomplete input");
      }
    },
    setSelected(city) {
      if (!city) {
        return;
      }
      if (this.cityFilter && city.name === this.cityFilter) {
        return;
      }
      this.$refs.cityAutocomplete.setSelected(city);
    },
    onFocus() {
      this.openOnFocus = true;
      this.$emit("focus");
    },
    select(city) {
      if (city) {
        this.$emit("select", city);
      } else {
        this.blur();
      }
    },
    blur() {
      this.openOnFocus = !this.openOnFocus;
      this.$refs.cityAutocomplete.isActive =
        !this.$refs.cityAutocomplete.isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-city::v-deep {
  height: 2.5rem;
  .dropdown-item {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
