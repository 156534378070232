/**
 * @param {string} seoName
 */
async function getProfileBySeoName(seoName) {
  return await this.$axios.get(`/profiles/${seoName}`);
}

async function getPhoneCountryCodes() {
  return await this.$axios.get("/phones/country-codes");
}

async function getPhoneCountryCode(iso) {
  return await this.$axios.get(`/phones/country-codes/${iso}`);
}

async function getPhoneCountryCodeById(id) {
  return await this.$axios.get(`/phones/country-codes/id/${id}`);
}

async function getCounties() {
  const resp = await this.$axios.get("/counties");
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to fetch counties");
}

/**
 * @param {string} seoName
 */
async function getCityBySeoName(seoName) {
  const resp = await this.$axios.get(`/cities/${seoName}`);
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch city by seo name");
}

async function getPeopleCount({
  professionSeoName,
  professionTaskSeoName,
  citySeoName,
}) {
  const resp = await this.$axios.get("/people/count", {
    params: {
      professionSeoName,
      professionTaskSeoName,
      citySeoName,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to fetch people count");
}

async function getQuestions({
  professionSeoName,
  minAnswerCount,
  page,
  size,
  order,
  orderBy,
}) {
  const resp = await this.$axios.get("/questions", {
    params: {
      professionSeoName,
      minAnswerCount,
      page,
      size,
      order,
      orderBy,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  return null;
}

/**
 *
 * @param {string} seoName
 */
async function getCountyBySeoName(seoName) {
  const resp = await this.$axios.get(`/counties/${seoName}`);
  if (resp.status === 200 || resp.status === 301) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch county by seo name");
}

async function getCities({
  countySeoName,
  minPopulation,
  order,
  orderBy,
} = {}) {
  const resp = await this.$axios.get("/cities", {
    params: {
      countySeoName,
      minPopulation,
      order,
      orderBy,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  console.error("Failed to fetch cities", resp);
  throw new Error("Failed to fetch cities");
}

/**
 * @param {string} seoName
 */
async function getProfessionBySeoName(seoName) {
  const resp = await this.$axios.get(`/professions/${seoName}`);
  if (resp.status === 200 || resp.status === 301) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch profession by seo name");
}

async function getProfessionsWithTasks({ page, size }) {
  const resp = await this.$axios.get("/professions/profession-tasks", {
    params: {
      page,
      size,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch professions with profession tasks");
}

/**
 * @param {string} seoName
 */
async function getPopularProfessionTasks({
  professionSeoName,
  citySeoName,
  limit,
  minimumProfessionalCount,
  minSearch,
}) {
  const resp = await this.$axios.get("/profession-tasks/popular", {
    params: {
      professionSeoName,
      citySeoName,
      limit,
      minimumProfessionalCount,
      minSearch,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to fetch popular profession tasks");
}

/**
 * @param {string} keyword
 */
async function searchProfessionTasksByKeyword(keyword) {
  const resp = await this.$axios.get("/profession-tasks/search", {
    params: {
      keyword,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to search profession tasks");
}

/**
 * @param {string} keyword
 */
async function getProfessionTaskBySeoName(seoName) {
  const resp = await this.$axios.get(`/profession-tasks/${seoName}`);
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to fetch profession task by seo name");
}

/**
 * @param {string} seoName
 */
async function getPeopleByCityAndProfession({
  citySeoName,
  professionSeoName,
  page,
  size,
}) {
  const resp = await this.$axios.get(
    `/people/${professionSeoName}/${citySeoName}`,
    {
      params: {
        page,
        size,
      },
    }
  );
  if (resp.status === 200) {
    return resp.data;
  }

  return null;
}

/**
 * @param {string} seoName
 */
async function getInspirationBySeoName(seoName) {
  const resp = await this.$axios.get(`/inspirations/${seoName}`);
  if (resp.status === 200 || resp.status === 301) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch inspiration by seo name");
}

async function getInspirations({
  page,
  size,
  professionSeoName,
  professionTaskSeoName,
  excludeId,
  keyword,
}) {
  const resp = await this.$axios.get("/inspirations", {
    params: {
      page,
      size,
      professionSeoName,
      professionTaskSeoName,
      excludeId,
      keyword,
    },
  });
  if (resp.status === 200 || resp.status === 301) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch inspirations");
}

/**
 * @param {string} path
 */
async function getRedirect(path) {
  const resp = await this.$axios.get("/redirect", {
    params: {
      path,
    },
  });

  if (resp.status === 200) {
    return resp.data.location;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch redirect");
}

async function getAllPriceCalculators() {
  const resp = await this.$axios.get("/price-calculators");
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error("Failed to fetch price calculators");
}

async function getBlogArticles({
  profession,
  professionTask,
  isHighlighted,
  page,
  size,
  tags,
  limit,
  keyword,
}) {
  const resp = await this.$axios.get("/blog/articles", {
    params: {
      profession,
      professionTask,
      isHighlighted,
      tags: tags?.join(","),
      page,
      limit,
      keyword,
      size,
    },
  });
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }
  throw new Error("Failed to fetch blog article");
}

async function getBlogArticleBySeoName(seoName) {
  const resp = await this.$axios.get(`/blog/${seoName}`);
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }

  throw new Error("Failed to fetch blog article");
}

async function getBlogCollections() {
  const resp = await this.$axios.get("/blog/collections");
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }

  throw new Error("Failed to fetch blog collections");
}

async function getBlogCollectionBySeoName(seoName) {
  const resp = await this.$axios.get(`/blog/collections/${seoName}`);
  if (resp.status === 200) {
    return resp.data;
  }
  if (resp.status === 404) {
    return null;
  }

  throw new Error("Failed to fetch blog collection");
}

const useRestClient = (axios) => {
  return {
    profiles: {
      bySeoName: getProfileBySeoName.bind({
        $axios: axios,
      }),
    },
    people: {
      byCityAndProfession: getPeopleByCityAndProfession.bind({ $axios: axios }),
      count: getPeopleCount.bind({ $axios: axios }),
    },
    cities: {
      bySeoName: getCityBySeoName.bind({ $axios: axios }),
      get: getCities.bind({ $axios: axios }),
    },
    professions: {
      bySeoName: getProfessionBySeoName.bind({ $axios: axios }),
      withTasks: getProfessionsWithTasks.bind({ $axios: axios }),
    },
    counties: {
      bySeoName: getCountyBySeoName.bind({ $axios: axios }),
      get: getCounties.bind({ $axios: axios }),
    },
    questions: {
      get: getQuestions.bind({ $axios: axios }),
    },
    professionTasks: {
      searchByKeyword: searchProfessionTasksByKeyword.bind({ $axios: axios }),
      popularByProfessionSeoName: getPopularProfessionTasks.bind({
        $axios: axios,
      }),
      bySeoName: getProfessionTaskBySeoName.bind({ $axios: axios }),
    },
    inspirations: {
      bySeoName: getInspirationBySeoName.bind({ $axios: axios }),
      get: getInspirations.bind({ $axios: axios }),
    },
    phones: {
      countryCodes: getPhoneCountryCodes.bind({ $axios: axios }),
      countryCode: getPhoneCountryCode.bind({ $axios: axios }),
      countryCodeById: getPhoneCountryCodeById.bind({ $axios: axios }),
    },
    redirect: {
      get: getRedirect.bind({ $axios: axios }),
    },
    priceCalculators: {
      getAll: getAllPriceCalculators.bind({ $axios: axios }),
    },
    blog: {
      getArticles: getBlogArticles.bind({ $axios: axios }),
      getArticleBySeoName: getBlogArticleBySeoName.bind({ $axios: axios }),
      getCollections: getBlogCollections.bind({ $axios: axios }),
      getCollectionBySeoName: getBlogCollectionBySeoName.bind({
        $axios: axios,
      }),
    },
  };
};

export default useRestClient;
