<template>
  <div
    class="bg-gray-300/20 shadow-md border-2 border-dashed border-gray-500 rounded-md"
    @drop.prevent="onDrop"
    @dragenter.prevent="dragging = true"
    @dragover.prevent
    @dragleave.prevent="dragging = false"
  >
    <slot name="drop" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dragging: false,
    };
  },
  computed: {},
  methods: {
    onDrop(event) {
      const files = event.dataTransfer.files;
      this.$emit("upload", files);
    },
  },
};
</script>
