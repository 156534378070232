// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";
import { PostHog } from "posthog-node";
import { v4 as uuidv4 } from "uuid";
import { FEATURE_FLAGS } from "~/experiments/posthog";

export const UUID_COOKIE = "joszaki-uuid";
export const FLAGS_COOKIE = "joszaki-flags";

export default async function (ctx, inject) {
  let uuid = getCookie(ctx, UUID_COOKIE);
  let posthogClient;
  let isVariant;

  let isFeatureEnabled;

  try {
    if (process.env.DEPLOY_STAGE === "production") {
      // if (process.env.DEPLOY_STAGE === 'dasdasdas' || !isVariant) {
      if (process.server) {
        const options = {
          host: process.env.POSTHOG_URL,
          featureFlagsPollingInterval: 14400000,
          // personalApiKey: process.env.POSTHOG_PERSONAL_KEY
        };
        posthogClient = new PostHog(process.env.POSTHOG_TRACKER_ID, options);

        if (!uuid) {
          uuid = uuidv4();
          setCookie(ctx, UUID_COOKIE, uuid);
        }

        // console.time('Execution Time')
        const flags = await posthogClient.getAllFlags(uuid);
        // console.timeEnd('Execution Time')
        setCookie(ctx, FLAGS_COOKIE, JSON.stringify(flags) || {});

        /**
         * @example context.$ab.isVariant('landing-search', 'test')
         * @example this.$ab.isVariant('landing-search', 'control')
         * @param {string} name name of the test/experiment
         * @param {string} variant name of the variant
         * @returns {boolean} true if test with given name exists and the variant matches given variant false otherwise
         */
        isVariant = (name, variant) => {
          return flags[name] === variant;
        };

        isFeatureEnabled = (name) => {
          return flags[name];
        };
      } else {
        posthogClient = posthog.init(process.env.POSTHOG_TRACKER_ID, {
          api_host: process.env.POSTHOG_URL,
          autocapture: false,
          capture_pageview: false,
          capture_pageleave: false,
          disable_session_recording: true,
          // disable_persistence: true,
          bootstrap: {
            distinctID: uuid,
            isIdentifiedID: true,
            featureFlags: JSON.parse(getCookie(ctx, FLAGS_COOKIE)),
          },
          loaded: function (posthog) {
            posthog.identify(uuid);
          },
        });
        window.posthog = posthogClient;
        isVariant = (name, variant) =>
          posthogClient.getFeatureFlag(name, { send_event: false }) === variant;

        isFeatureEnabled = (name) => {
          return posthogClient.isFeatureEnabled(name);
        };
      }
    } else {
      // Local development and joszaki.space
      isVariant = (name, variant) => {
        const featureFlag = FEATURE_FLAGS.find((ff) => ff.name === name);
        return featureFlag?.local === variant;
      };
      posthogClient = null;

      isFeatureEnabled = (name) => {
        const featureFlag = FEATURE_FLAGS.find((ff) => ff.name === name);
        return !!featureFlag?.local;
      };
    }
  } catch (err) {
    console.error(err, "posthog setup error");
    ctx.$sentry.captureException(err, {
      tags: {
        posthog: "setup",
      },
    });
    // Dummy variant function that turns off all experiments
    isVariant = () => false;
    // Dummy posthog client that reports error to sentry
    // TODO log error to ELK?
    posthogClient = {
      capture: (event, data) => {
        console.error("Could not send posthog event", event, data);
        ctx.$sentry.captureMessage("Could not send posthog event", {
          tags: {
            event,
          },
        });
      },
      identify: (id, data) => {
        console.error("Could not send posthog identify", id, data);
        ctx.$sentry.captureMessage("Could not send posthog identify", {
          tags: {
            id,
          },
        });
      },
      loadToolbar: () => {
        console.error("Could not load posthog toolbar");
      },
    };
  }

  inject("ab", {
    isVariant,
  });

  inject("flags", {
    isFeatureEnabled,
  });

  inject("posthog", posthogClient);
  inject("uuid", uuid);
}

/**
 * Get the cookie that holds the ids and variants denoting the experiments assigned to the user
 *
 * @param {*} ctx Nuxt context
 * @param {string} name name of the cookie
 * @returns {string | null} the value of the cookie or null
 */
function getCookie(ctx, name) {
  if (process.server && !ctx.req) {
    return null;
  }

  try {
    return ctx.$cookie.get(name) || null;
  } catch (err) {
    console.error(err);
    ctx.$sentry.captureException(err, {
      tags: {
        ssr: "optimize",
      },
    });
  }
  return null;
}

/**
 * Sets the exp cookie that is tracking the active AB tests
 *
 * @param {*} ctx Nuxt context
 * @param {string} name name of the cookie
 * @param {string} value value of the cookie

 */
function setCookie(ctx, name, value) {
  const oldCookie = getCookie(ctx, name);
  // skip upgrading the value if it's unchanged
  if (oldCookie === value) {
    return;
  }

  let domain = process.env.TOP_DOMAIN.split("//")[1];

  if (ctx?.i18n?.locale === "cs" && process.env.TOP_DOMAIN_CZ) {
    domain = process.env.TOP_DOMAIN_CZ.split("//")[1];
  }

  if (domain.includes(":")) {
    domain = domain.split(":")[0];
  }

  ctx.$cookie.set(name, value, {
    domain: `.${domain}`,
    path: "/",
    maxAge: 60 * 60 * 24 * 7 * 8 /* 8 weeks */,
  });
}
