import { loadAuthOnServer } from "~/components/_refactored/auth";

/**
 * Preload store data on the server side
 */
export default async (context) => {
  if (process.server) {
    await Promise.all([loadAuthOnServer(context)]);
  }
};
