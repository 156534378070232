const initState = () => {
  return {
    showSlogan: false,
    showProfessions: false,
    showCities: false,
    showNewTender: false,
    showHeaderButtons: true,
    showHeaderSearch: true,
  };
};

export const state = () => {
  return {
    ...initState(),
  };
};

export const getters = {
  showSlogan: (state) => state.showSlogan,
  showProfessions: (state) => state.showProfessions,
  showCities: (state) => state.showCities,
  showNewTender: (state) => state.showNewTender,
  showHeaderButtons: (state) => state.showHeaderButtons,
  showHeaderSearch: (state) => state.showHeaderSearch,
};

export const mutations = {
  setState(state, { name, value }) {
    state[name] = value;
  },
  reset(state) {
    Object.assign(state, initState());
  },
};

export const actions = {
  setShowSlogan({ commit }, value) {
    commit("setState", {
      name: "showSlogan",
      value,
    });
  },
  setShowProfessions({ commit }, value) {
    commit("setState", {
      name: "showProfessions",
      value,
    });
  },
  setShowCities({ commit }, value) {
    commit("setState", {
      name: "showCities",
      value,
    });
  },
  setShowNewTender({ commit }, value) {
    commit("setState", {
      name: "showNewTender",
      value,
    });
  },
  setShowHeaderButtons({ commit }, value) {
    commit("setState", {
      name: "showHeaderButtons",
      value,
    });
  },
  setShowHeaderSearch({ commit }, value) {
    commit("setState", {
      name: "showHeaderSearch",
      value,
    });
  },
  reset({ commit }) {
    commit("reset");
  },
};
