<template>
  <div
    class="has-background-white flex flex-col justify-center items-center p-5 rounded-lg"
    style="box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05)"
  >
    <span v-if="showTitle" class="text-2xl mb-4 font-bold text-[#337ab7]">
      {{ $t("searchBox.title") }}
    </span>

    <div class="block w-full">
      <div class="flex mb-1 items-baseline">
        <b-icon
          pack="fas"
          :class="profession ? 'has-text-green' : 'has-text-orange'"
          class="mr-1"
          icon="briefcase"
        />
        <p class="font-extrabold text-xl">{{ $t("searchBox.profession") }}:</p>
      </div>

      <AutocompleteProfession
        ref="prof"
        clearable
        :placeholder="$t('searchBox.professionPlaceholder')"
        @select="selectProfession"
      />
    </div>

    <div class="block w-full">
      <div class="flex mb-1 items-baseline">
        <b-icon
          pack="fas"
          :class="city ? 'has-text-green' : 'has-text-orange'"
          class="mr-1"
          icon="map-marker-alt"
        />
        <p class="font-extrabold text-xl">{{ $t("searchBox.city") }}:</p>
      </div>
      <AutocompleteCity
        clearable
        :placeholder="$t('searchBox.cityPlaceholder')"
        @select="selectCity"
      />
    </div>
    <b-button
      type="is-primary"
      class="is-fullwidth button-miltiline"
      :class="{ 'is-loading': loading }"
      icon-left="search"
      icon-pack="fas"
      @click="search"
    >
      {{ $t("searchBox.button") }}
    </b-button>
  </div>
</template>

<script>
import AutocompleteProfession from "../AutocompleteProfession.vue";
import AutocompleteCity from "~/components/AutocompleteCity.vue";

export default {
  components: {
    AutocompleteCity,
    AutocompleteProfession,
  },
  props: {
    inputCity: {
      type: Object,
      default: () => null,
    },
    inputProfession: {
      type: Object,
      default: () => null,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      city: null,
      profession: null,
      loading: false,
    };
  },
  mounted() {
    if (this.inputProfession) {
      this.profession = this.inputProfession;
      this.$refs.prof.setSelected(this.inputProfession);
    }
  },
  methods: {
    selectCity(city) {
      this.city = city;
    },
    selectProfession(profession) {
      this.profession = profession;
    },
    search() {
      try {
        this.loading = true;
        if (this.city && this.profession) {
          this.$trackers.trackClick("SearchBoxButton");

          if (this.$helpers.is301(this.profession)) {
            if (this.city.parentCity) {
              this.$router.push(
                this.localePath({
                  name: "professionalsParentCity",
                  params: {
                    profession: this.profession.seoName,
                    parentCity: this.city.parentCity.seoName,
                    city: this.city.seoName,
                  },
                })
              );
              return;
            }
            this.$router.push(
              this.localePath({
                name: "professionals",
                params: {
                  profession: this.profession.seoName,
                  city: this.city.seoName,
                },
              })
            );
            return;
          }
          const toAdd = [];
          if (this.city.parentCity) {
            toAdd.push(this.city.parentCity.seoName);
          }
          toAdd.push(this.city.seoName);
          const url = this.$helpers.addToUrl(
            this.$helpers.topDomain,
            this.profession.seoName,
            ...toAdd
          );
          window.location.assign(url);
        } else if (!this.city) {
          this.$buefy.toast.open({
            message: this.$t("searchBox.inputCityError"),
            type: "is-warning",
          });
        } else if (!this.profession) {
          this.$buefy.toast.open({
            message: this.$t("searchBox.inputProfessionError"),
            type: "is-danger",
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
