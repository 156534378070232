// import Vue from 'vue';
// import Vuex from 'vuex';

// Vue.use(Vuex);

export const state = () => {
  return {
    actualLocation: {
      id: "2",
      name: "Budapest",
      seoName: "budapest",
    },
    actualProfession: {
      id: "51",
      name: "Villanyszerelő",
      seoName: "villanyszerelo",
      objective: "villanyszerelőt",
    },
  };
};

export const getters = {};

export const mutations = {
  setActualLocation(state, payload) {
    state.actualLocation = payload;
  },
  setActualProfession(state, payload) {
    state.actualProfession = payload;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch }, context) {
    await dispatch("data/loadBackendData", context);
  },
};
