<template>
  <b-autocomplete
    v-if="professionGroups"
    ref="professionAutocomplete"
    v-model="professionFilter"
    class="autocomplete-profession"
    icon-pack="fas"
    icon="briefcase"
    :data="filteredProfessions"
    open-on-focus
    :custom-formatter="formatter"
    group-field="group"
    :keep-first="true"
    v-bind="$attrs"
    @focus="onFocus"
    @select="select"
  >
    <template #empty>
      <span>
        {{ $t("professionAutocomplete.empty") }}
      </span>
    </template>
  </b-autocomplete>
</template>

<script>
const KEYWORD = "keyword";

export default {
  props: {
    prefill: {
      type: Object,
      default: null,
    },
    keywordSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      professionFilter: "",
      focused: false,
    };
  },
  computed: {
    professionGroups() {
      return this.focused ? this.$store.getters["data/professionGroups"] : [];
    },
    professions() {
      const result = [];
      for (const pg of this.professionGroups) {
        for (const p of pg.professions) {
          result.push({
            ...p,
            group: pg.name,
          });
        }
      }
      return result;
    },
    normalizedProfessionFilter() {
      return this.$helpers.normalizeInput(this.professionFilter);
    },
    filteredProfessions() {
      if (!this.professionFilter) {
        return this.professions;
      }
      const rankedList = [];
      for (const p of this.professions) {
        const index = this.$helpers
          .normalizeInput(p.name)
          .indexOf(this.normalizedProfessionFilter);
        if (index > -1) {
          rankedList.push({
            index,
            profession: p,
          });
        }
      }
      rankedList.sort((a, b) => {
        if (a.index === b.index) {
          return a.profession.seoName > b.profession.seoName ? 1 : -1;
        }
        return a.index > b.index ? 1 : -1;
      });
      const result = rankedList.map((x) => x.profession);
      if (this.keywordSearch && this.professionFilter) {
        // Hacky ez workaround
        result.push({
          id: KEYWORD,
          group: this.$t("peopleHeader.profession.keyword"),
          name: this.professionFilter,
        });
      }
      return result;
    },
  },
  mounted() {
    this.setSelected(this.prefill);
  },
  methods: {
    focusInput() {
      try {
        this.$refs.professionAutocomplete.focus();
      } catch (error) {
        console.info("Cant auto focus professionAutocomplete input");
      }
    },
    formatter(value) {
      if (!value) {
        return "NULL";
      }
      return this.$helpers.capitalize(value.name);
    },
    setSelected(profession) {
      if (!profession) {
        return;
      }
      if (this.professionFilter && profession.name === this.professionFilter) {
        return;
      }
      this.$refs.professionAutocomplete.setSelected(profession);
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    select(profession) {
      let toEmit = profession;
      if (profession && profession.id === KEYWORD) {
        // Emit keyword instead of hacky object
        toEmit = profession.name;
      }

      this.$emit("select", toEmit);
    },
    blur() {
      this.$refs.professionAutocomplete.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-profession::v-deep {
  height: 2.5rem;
  .dropdown-item {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
