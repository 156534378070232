import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_55082072 from 'nuxt_plugin_sentryserver_55082072' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_6fc27e3f from 'nuxt_plugin_sentryclient_6fc27e3f' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_2eeeef95 from 'nuxt_plugin_plugin_2eeeef95' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtleaflet_5d60573c from 'nuxt_plugin_nuxtleaflet_5d60573c' // Source: ./nuxt-leaflet.js (mode: 'client')
import nuxt_plugin_nuxtmq_cfefa16a from 'nuxt_plugin_nuxtmq_cfefa16a' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_3fa11cab from 'nuxt_plugin_cookieuniversalnuxt_3fa11cab' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vlazyload_2c3871b1 from 'nuxt_plugin_vlazyload_2c3871b1' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_markdownit_4db17d4a from 'nuxt_plugin_markdownit_4db17d4a' // Source: ./markdownit.js (mode: 'all')
import nuxt_plugin_axios_926039fa from 'nuxt_plugin_axios_926039fa' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_83e2dcd0 from 'nuxt_plugin_pluginutils_83e2dcd0' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2ab118e3 from 'nuxt_plugin_pluginrouting_2ab118e3' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_cd4b9528 from 'nuxt_plugin_pluginmain_cd4b9528' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_workbox_7798b942 from 'nuxt_plugin_workbox_7798b942' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_3a9f1b72 from 'nuxt_plugin_metaplugin_3a9f1b72' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_3f46c0e6 from 'nuxt_plugin_iconplugin_3f46c0e6' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_deviceplugin_10bc70e4 from 'nuxt_plugin_deviceplugin_10bc70e4' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_graphqlrequest_60cdcc2e from 'nuxt_plugin_graphqlrequest_60cdcc2e' // Source: ./graphql-request.js (mode: 'all')
import nuxt_plugin_distplugin6dfc3bb9_3e42e46e from 'nuxt_plugin_distplugin6dfc3bb9_3e42e46e' // Source: ./dist.plugin.6dfc3bb9.mjs (mode: 'all')
import nuxt_plugin_startup_2dcaca50 from 'nuxt_plugin_startup_2dcaca50' // Source: ../plugins/startup.js (mode: 'server')
import nuxt_plugin_serverLogger_64c655ad from 'nuxt_plugin_serverLogger_64c655ad' // Source: ../plugins/serverLogger.js (mode: 'server')
import nuxt_plugin_clientLogger_33562a25 from 'nuxt_plugin_clientLogger_33562a25' // Source: ../plugins/clientLogger.js (mode: 'client')
import nuxt_plugin_mitt_1e5f57a4 from 'nuxt_plugin_mitt_1e5f57a4' // Source: ../plugins/mitt.js (mode: 'all')
import nuxt_plugin_joszaki_426bc09b from 'nuxt_plugin_joszaki_426bc09b' // Source: ../plugins/joszaki.js (mode: 'all')
import nuxt_plugin_directives_5f2656fc from 'nuxt_plugin_directives_5f2656fc' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_helpers_a37edf2e from 'nuxt_plugin_helpers_a37edf2e' // Source: ../plugins/helpers.js (mode: 'all')
import nuxt_plugin_postTranslation_3fac6b06 from 'nuxt_plugin_postTranslation_3fac6b06' // Source: ../plugins/postTranslation.js (mode: 'all')
import nuxt_plugin_graphql_605dcef6 from 'nuxt_plugin_graphql_605dcef6' // Source: ../plugins/graphql.js (mode: 'all')
import nuxt_plugin_buefy_d1269c2e from 'nuxt_plugin_buefy_d1269c2e' // Source: ../plugins/buefy.js (mode: 'all')
import nuxt_plugin_filters_2b4f519a from 'nuxt_plugin_filters_2b4f519a' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_iconify_4e05364b from 'nuxt_plugin_iconify_4e05364b' // Source: ../plugins/iconify.js (mode: 'all')
import nuxt_plugin_multiselect_b7ff9c0e from 'nuxt_plugin_multiselect_b7ff9c0e' // Source: ../plugins/multiselect.js (mode: 'all')
import nuxt_plugin_veevalidate_3f7cf8b3 from 'nuxt_plugin_veevalidate_3f7cf8b3' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_vuelidate_7b59e9fd from 'nuxt_plugin_vuelidate_7b59e9fd' // Source: ../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_vuecontentplaceholders_b191d476 from 'nuxt_plugin_vuecontentplaceholders_b191d476' // Source: ../plugins/vue-content-placeholders.js (mode: 'all')
import nuxt_plugin_global_c4edad06 from 'nuxt_plugin_global_c4edad06' // Source: ../plugins/global.js (mode: 'all')
import nuxt_plugin_mateavailibility_278fc17a from 'nuxt_plugin_mateavailibility_278fc17a' // Source: ../plugins/mate-availibility.js (mode: 'all')
import nuxt_plugin_redis_74e3011a from 'nuxt_plugin_redis_74e3011a' // Source: ../plugins/redis.js (mode: 'server')
import nuxt_plugin_campaignscookie_66a9a7e5 from 'nuxt_plugin_campaignscookie_66a9a7e5' // Source: ../plugins/campaigns-cookie (mode: 'client')
import nuxt_plugin_clientsideglobal_387d7960 from 'nuxt_plugin_clientsideglobal_387d7960' // Source: ../plugins/clientside-global.js (mode: 'client')
import nuxt_plugin_gtm_63eb8124 from 'nuxt_plugin_gtm_63eb8124' // Source: ../plugins/gtm.js (mode: 'client')
import nuxt_plugin_observevisibility_02418986 from 'nuxt_plugin_observevisibility_02418986' // Source: ../plugins/observe-visibility.js (mode: 'client')
import nuxt_plugin_optimize_0e8679c3 from 'nuxt_plugin_optimize_0e8679c3' // Source: ../plugins/optimize.js (mode: 'client')
import nuxt_plugin_vuetextareaautogrowdirective_ed871d0e from 'nuxt_plugin_vuetextareaautogrowdirective_ed871d0e' // Source: ../plugins/vue-textarea-autogrow-directive (mode: 'client')
import nuxt_plugin_webvitals_3ad3e600 from 'nuxt_plugin_webvitals_3ad3e600' // Source: ../plugins/web-vitals.js (mode: 'client')
import nuxt_plugin_ab_fbb25682 from 'nuxt_plugin_ab_fbb25682' // Source: ../plugins/ab.js (mode: 'all')
import nuxt_plugin_trackers_03a950b6 from 'nuxt_plugin_trackers_03a950b6' // Source: ../plugins/trackers.js (mode: 'client')
import nuxt_plugin_vueInstantsearch_0566befd from 'nuxt_plugin_vueInstantsearch_0566befd' // Source: ../plugins/vueInstantsearch.js (mode: 'client')
import nuxt_plugin_typesense_eab5bbcc from 'nuxt_plugin_typesense_eab5bbcc' // Source: ../plugins/typesense.js (mode: 'all')
import nuxt_plugin_ssrOptimize_4be9e7c2 from 'nuxt_plugin_ssrOptimize_4be9e7c2' // Source: ../plugins/ssrOptimize.js (mode: 'all')
import nuxt_plugin_axios_54e49ad0 from 'nuxt_plugin_axios_54e49ad0' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_noBrand_eb23d5f0 from 'nuxt_plugin_noBrand_eb23d5f0' // Source: ../plugins/noBrand.js (mode: 'all')
import nuxt_plugin_piniaServerData_7d0674f4 from 'nuxt_plugin_piniaServerData_7d0674f4' // Source: ../plugins/piniaServerData.js (mode: 'all')
import nuxt_plugin_meta_7d87abe7 from 'nuxt_plugin_meta_7d87abe7' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"property":"og:type","content":"website"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"HandheldFriendly","content":"true"},{"hid":"description","name":"description","content":""}],"script":[{"innerHTML":"!function(){var e,t,n,i,r={passive:!0,capture:!0},a=new Date,o=function(){i=[],t=-1,e=null,f(addEventListener)},c=function(i,r){e||(e=r,t=i,n=new Date,f(removeEventListener),u())},u=function(){if(t\u003E=0&&t\u003Cn-a){var r={entryType:\"first-input\",name:e.type,target:e.target,cancelable:e.cancelable,startTime:e.timeStamp,processingStart:e.timeStamp+t};i.forEach((function(e){e(r)})),i=[]}},s=function(e){if(e.cancelable){var t=(e.timeStamp\u003E1e12?new Date:performance.now())-e.timeStamp;\"pointerdown\"==e.type?function(e,t){var n=function(){c(e,t),a()},i=function(){a()},a=function(){removeEventListener(\"pointerup\",n,r),removeEventListener(\"pointercancel\",i,r)};addEventListener(\"pointerup\",n,r),addEventListener(\"pointercancel\",i,r)}(t,e):c(t,e)}},f=function(e){[\"mousedown\",\"keydown\",\"touchstart\",\"pointerdown\"].forEach((function(t){return e(t,s,r)}))},p=\"hidden\"===document.visibilityState?0:1\u002F0;addEventListener(\"visibilitychange\",(function e(t){\"hidden\"===document.visibilityState&&(p=t.timeStamp,removeEventListener(\"visibilitychange\",e,!0))}),!0);o(),self.webVitals={firstInputPolyfill:function(e){i.push(e),u()},resetFirstInputPolyfill:o,get firstHiddenTime(){return p}}}();\n"}],"__dangerouslyDisableSanitizers":["script"],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_55082072 === 'function') {
    await nuxt_plugin_sentryserver_55082072(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_6fc27e3f === 'function') {
    await nuxt_plugin_sentryclient_6fc27e3f(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_2eeeef95 === 'function') {
    await nuxt_plugin_plugin_2eeeef95(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtleaflet_5d60573c === 'function') {
    await nuxt_plugin_nuxtleaflet_5d60573c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_cfefa16a === 'function') {
    await nuxt_plugin_nuxtmq_cfefa16a(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_3fa11cab === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_3fa11cab(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_2c3871b1 === 'function') {
    await nuxt_plugin_vlazyload_2c3871b1(app.context, inject)
  }

  if (typeof nuxt_plugin_markdownit_4db17d4a === 'function') {
    await nuxt_plugin_markdownit_4db17d4a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_926039fa === 'function') {
    await nuxt_plugin_axios_926039fa(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_83e2dcd0 === 'function') {
    await nuxt_plugin_pluginutils_83e2dcd0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2ab118e3 === 'function') {
    await nuxt_plugin_pluginrouting_2ab118e3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_cd4b9528 === 'function') {
    await nuxt_plugin_pluginmain_cd4b9528(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_7798b942 === 'function') {
    await nuxt_plugin_workbox_7798b942(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_3a9f1b72 === 'function') {
    await nuxt_plugin_metaplugin_3a9f1b72(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_3f46c0e6 === 'function') {
    await nuxt_plugin_iconplugin_3f46c0e6(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_10bc70e4 === 'function') {
    await nuxt_plugin_deviceplugin_10bc70e4(app.context, inject)
  }

  if (typeof nuxt_plugin_graphqlrequest_60cdcc2e === 'function') {
    await nuxt_plugin_graphqlrequest_60cdcc2e(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin6dfc3bb9_3e42e46e === 'function') {
    await nuxt_plugin_distplugin6dfc3bb9_3e42e46e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_startup_2dcaca50 === 'function') {
    await nuxt_plugin_startup_2dcaca50(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_serverLogger_64c655ad === 'function') {
    await nuxt_plugin_serverLogger_64c655ad(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientLogger_33562a25 === 'function') {
    await nuxt_plugin_clientLogger_33562a25(app.context, inject)
  }

  if (typeof nuxt_plugin_mitt_1e5f57a4 === 'function') {
    await nuxt_plugin_mitt_1e5f57a4(app.context, inject)
  }

  if (typeof nuxt_plugin_joszaki_426bc09b === 'function') {
    await nuxt_plugin_joszaki_426bc09b(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_5f2656fc === 'function') {
    await nuxt_plugin_directives_5f2656fc(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_a37edf2e === 'function') {
    await nuxt_plugin_helpers_a37edf2e(app.context, inject)
  }

  if (typeof nuxt_plugin_postTranslation_3fac6b06 === 'function') {
    await nuxt_plugin_postTranslation_3fac6b06(app.context, inject)
  }

  if (typeof nuxt_plugin_graphql_605dcef6 === 'function') {
    await nuxt_plugin_graphql_605dcef6(app.context, inject)
  }

  if (typeof nuxt_plugin_buefy_d1269c2e === 'function') {
    await nuxt_plugin_buefy_d1269c2e(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2b4f519a === 'function') {
    await nuxt_plugin_filters_2b4f519a(app.context, inject)
  }

  if (typeof nuxt_plugin_iconify_4e05364b === 'function') {
    await nuxt_plugin_iconify_4e05364b(app.context, inject)
  }

  if (typeof nuxt_plugin_multiselect_b7ff9c0e === 'function') {
    await nuxt_plugin_multiselect_b7ff9c0e(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_3f7cf8b3 === 'function') {
    await nuxt_plugin_veevalidate_3f7cf8b3(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_7b59e9fd === 'function') {
    await nuxt_plugin_vuelidate_7b59e9fd(app.context, inject)
  }

  if (typeof nuxt_plugin_vuecontentplaceholders_b191d476 === 'function') {
    await nuxt_plugin_vuecontentplaceholders_b191d476(app.context, inject)
  }

  if (typeof nuxt_plugin_global_c4edad06 === 'function') {
    await nuxt_plugin_global_c4edad06(app.context, inject)
  }

  if (typeof nuxt_plugin_mateavailibility_278fc17a === 'function') {
    await nuxt_plugin_mateavailibility_278fc17a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_redis_74e3011a === 'function') {
    await nuxt_plugin_redis_74e3011a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_campaignscookie_66a9a7e5 === 'function') {
    await nuxt_plugin_campaignscookie_66a9a7e5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientsideglobal_387d7960 === 'function') {
    await nuxt_plugin_clientsideglobal_387d7960(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_63eb8124 === 'function') {
    await nuxt_plugin_gtm_63eb8124(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_observevisibility_02418986 === 'function') {
    await nuxt_plugin_observevisibility_02418986(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_optimize_0e8679c3 === 'function') {
    await nuxt_plugin_optimize_0e8679c3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetextareaautogrowdirective_ed871d0e === 'function') {
    await nuxt_plugin_vuetextareaautogrowdirective_ed871d0e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webvitals_3ad3e600 === 'function') {
    await nuxt_plugin_webvitals_3ad3e600(app.context, inject)
  }

  if (typeof nuxt_plugin_ab_fbb25682 === 'function') {
    await nuxt_plugin_ab_fbb25682(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trackers_03a950b6 === 'function') {
    await nuxt_plugin_trackers_03a950b6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueInstantsearch_0566befd === 'function') {
    await nuxt_plugin_vueInstantsearch_0566befd(app.context, inject)
  }

  if (typeof nuxt_plugin_typesense_eab5bbcc === 'function') {
    await nuxt_plugin_typesense_eab5bbcc(app.context, inject)
  }

  if (typeof nuxt_plugin_ssrOptimize_4be9e7c2 === 'function') {
    await nuxt_plugin_ssrOptimize_4be9e7c2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_54e49ad0 === 'function') {
    await nuxt_plugin_axios_54e49ad0(app.context, inject)
  }

  if (typeof nuxt_plugin_noBrand_eb23d5f0 === 'function') {
    await nuxt_plugin_noBrand_eb23d5f0(app.context, inject)
  }

  if (typeof nuxt_plugin_piniaServerData_7d0674f4 === 'function') {
    await nuxt_plugin_piniaServerData_7d0674f4(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_7d87abe7 === 'function') {
    await nuxt_plugin_meta_7d87abe7(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
