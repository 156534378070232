<template>
  <span
    :is="component"
    class="font-bold rounded-md px-2 flex items-center border-2"
    :class="{
      [bgClass]: true,
      [hoverClass]: hoverable,
      [textColor]: true,
      [borderColor]: true,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "JoszakiPill",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: function (value) {
        return [
          "default",
          "info",
          "primary",
          "success",
          "warning",
          "danger",
          "error",
          "rating",
        ].includes(value);
      },
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: "span",
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgClass() {
      if (this.inverted) {
        return "bg-white";
      }
      if (this.type === "default") {
        return "bg-gray-600";
      }

      return `bg-${this.type}`;
    },
    borderColor() {
      return `border-${this.type}`;
    },
    textColor() {
      if (this.inverted) {
        return `!text-${this.type}`;
      }
      return "!text-white";
    },
    hoverClass() {
      if (!this.hoverable) {
        return "";
      }

      if (this.type === "default") {
        return "bg-gray-700";
      }

      return `bg-${this.type}-darker`;
    },
  },
};
</script>
