export class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class UserSpaceError extends CustomError {
  constructor(message, cause) {
    super(message);
    this.cause = cause;
  }
}

export class AnalyticsError extends CustomError {
  constructor(cause) {
    super("Failed to send analytics");
    this.cause = cause;
  }
}

export class UserError extends CustomError {}

export class InvalidArgumentError extends CustomError {}
