var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.actualComponent,_vm._b({tag:"component",staticClass:"joszaki-button relative flex items-center justify-center",class:[
    _vm.bgColor,
    _vm.textColor,
    _vm.borderColor,
    {
      'w-full': _vm.expanded,
      '!cursor-not-allowed': _vm.disabled,
      'border-solid border-2': _vm.outline,
      'text-white': !_vm.inverted,
      'p-1': _vm.size === 'sm',
      'p-3 !text-xl font-bold': _vm.size === 'lg',
      '!rounded-none': _vm.styling === 'squared',
      'inline-flex': _vm.inline,
    },
  ],attrs:{"title":_vm.title,"type":_vm.isSubmit ? 'submit' : 'button',"href":_vm.href,"to":_vm.to},on:{"click":_vm.onClick}},'component',_vm.$attrs,false),[(_vm.iconLeft && !_vm.loading && !_vm.disabled)?_c('IconComponent',{class:{
      'mr-2': _vm.size !== 'lg' && _vm.$slots.default,
      'mr-4': _vm.size === 'lg' && _vm.$slots.default,
    },attrs:{"icon":_vm.iconLeft,"pack":_vm.iconLeftPack}}):_vm._e(),(_vm.loading)?_c('IconComponent',{staticClass:"mr-2 animate-spin",attrs:{"icon":"spinner","pack":"fas"}}):_vm._e(),(_vm.disabled)?_c('IconComponent',{class:{
      'mr-2': _vm.size !== 'lg' && _vm.$slots.default,
      'mr-4': _vm.size === 'lg' && _vm.$slots.default,
    },attrs:{"icon":"ban","pack":"fas"}}):_vm._e(),_vm._t("default"),(_vm.iconRight)?_c('IconComponent',{staticClass:"ml-2",class:{
      'ml-4': _vm.size === 'lg',
    },attrs:{"icon":_vm.iconRight,"pack":_vm.iconRightPack}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }