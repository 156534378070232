<template>
  <div class="grid grid-cols-1 md:grid-cols-[5fr_2fr_5fr] pb-14">
    <div>
      <QuickOffer @requestTender="$emit('requestTender', $event)" />
    </div>
    <div class="orSticker">
      <p>{{ $t("landing.or") }}</p>
    </div>
    <div>
      <SearchBox />
    </div>
  </div>
</template>

<script>
import QuickOffer from "~/components/_refactored/app/boxes/QuickOffer.vue";
import SearchBox from "~/components/people/SearchBox.vue";

export default {
  components: { SearchBox, QuickOffer },
};
</script>

<style lang="scss" scoped>
.orSticker {
  background: #f0ad4e;
  border-radius: 10000px;
  color: #fff;
  font-size: 1.5em;
  width: 5em;
  height: 5em;
  margin: auto;
  text-transform: uppercase;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    height: 1.5em;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    p {
      transform: translateY(5px);
    }

    &:after {
      content: "";
      position: absolute;
      top: 1.4em;
      left: -0.6em;
      width: 0;
      height: 0;
      border-top: 1em #f0ad4e solid;
      border-left: 3.2em transparent solid;
      border-right: 3.2em transparent solid;
    }
  }
}
</style>
