var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center gap-2"},[(_vm.paginationInfo.hasPrevious)?[_c('PaginationButton',{attrs:{"url":_vm.getPageUrl(_vm.paginationInfo.current - 1)},on:{"click":function($event){return _vm.selectPage(_vm.paginationInfo.current - 1)}}},[_c('IconComponent',{attrs:{"icon":"angle-left"}})],1)]:_vm._e(),_vm._l((_vm.getIndexes),function(index){return _c('PaginationButton',{key:index,class:{
      'block md:hidden':
        _vm.paginationInfo.first === _vm.paginationInfo.current ||
        _vm.paginationInfo.current === _vm.paginationInfo.last
          ? Math.abs(_vm.paginationInfo.current - index) > _vm.increment
          : Math.abs(_vm.paginationInfo.current - index) > _vm.increment - 1, // On desktop show one less
    },attrs:{"active":index === _vm.paginationInfo.current,"url":_vm.getPageUrl(index)},on:{"click":function($event){return _vm.selectPage(index)}}},[_vm._v(" "+_vm._s(index)+" ")])}),(_vm.paginationInfo.hasNext)?[_c('PaginationButton',{attrs:{"url":_vm.getPageUrl(_vm.paginationInfo.current + 1)},on:{"click":function($event){return _vm.selectPage(_vm.paginationInfo.current + 1)}}},[_c('IconComponent',{attrs:{"icon":"angle-right"}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }