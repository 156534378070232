// Utility functions for working with experiments
import { CustomError } from "~/helpers/error";

export function hasDevVariant(experiment) {
  return Object.prototype.hasOwnProperty.call(experiment, "devVariant");
}

/**
 * Answers whether or not experiment is enabled for the current environment
 *
 * @param {*} experiment
 * @returns true if enabled
 */
export function isExperimentEnabled(experiment) {
  return (
    (experiment.enabled && process.env.NODE_ENV === "production") ||
    (experiment.devEnabled &&
      (process.env.DEPLOY_STAGE !== "production" ||
        process.env.NODE_ENV === "development"))
  );
}

class InvalidExperimentError extends CustomError {}

const REQUIRED_KEYS = ["name", "enabled", "variants", "id"];
const REQUIRED_STRINGS = ["name", "id"];
const ALLOWED_KEYS = [
  "name",
  "enabled",
  "variants",
  "id",
  "devEnabled",
  "devVariant",
  "sections",
  "isEligible",
];
export function validateExperiment(experiment) {
  for (const key of REQUIRED_KEYS) {
    if (!Object.prototype.hasOwnProperty.call(experiment, key)) {
      throw new InvalidExperimentError(`Missing property: ${key}`);
    }
  }

  for (const key of Object.keys(experiment)) {
    if (!ALLOWED_KEYS.includes(key)) {
      throw new InvalidExperimentError(`Unknown property: ${key}`);
    }
  }

  for (const key of REQUIRED_STRINGS) {
    if (!experiment[key]) {
      throw new InvalidExperimentError(`Value of '${key}' is required`);
    }
  }

  if (!Array.isArray(experiment.variants)) {
    throw new InvalidExperimentError("variants has to be an Array");
  }

  if (experiment.variants.length < 1) {
    throw new InvalidExperimentError("0 variants provided");
  }

  if (
    Object.prototype.hasOwnProperty.call(experiment, "isEligible") &&
    typeof experiment.isEligible !== "function"
  ) {
    throw new InvalidExperimentError("isEligible must be a function");
  }

  for (const weight of experiment.variants) {
    if (weight < 1) {
      throw new InvalidExperimentError("variant with weight less than 1");
    }
  }
}
