import Vue from "vue";
import IconComponent from "~/components/IconComponent";
import JoszakiButton from "~/components/_refactored/common/JoszakiButton";
import JoszakiDataTable from "~/components/_refactored/common/JoszakiDataTable.vue";
import JoszakiDropdownButton from "~/components/_refactored/common/JoszakiDropdownButton.vue";
import JoszakiPill from "~/components/_refactored/common/JoszakiPill.vue";
import JoszakiAutocomplete from "~/components/_refactored/common/JoszakiAutocomplete";
import JoszakiInput from "~/components/_refactored/common/JoszakiInput";
import JoszakiGroupInput from "~/components/_refactored/common/JoszakiGroupInput";
import JoszakiGroupButton from "~/components/_refactored/common/JoszakiGroupButton.vue";
import JoszakiLoading from "~/components/joszaki/JoszakiLoading";
import JoszakiCheckbox from "~/components/_refactored/common/JoszakiCheckbox";
import JoszakiModal from "~/components/_refactored/common/JoszakiModal";
import JoszakiDropdown from "~/components/_refactored/common/JoszakiDropdown";
import JoszakiRadio from "~/components/_refactored/common/JoszakiRadio";
import JoszakiSwitch from "~/components/_refactored/common/JoszakiSwitch";
import JoszakiMessage from "~/components/_refactored/common/JoszakiMessage";
import JoszakiTextArea from "~/components/_refactored/common/JoszakiTextArea";
import JoszakiDropdownOld from "~/components/_refactored/common/JoszakiDropdownOld";
import JoszakiPhoneInput from "~/components/_refactored/common/JoszakiPhoneInput";
import JoszakiInputOld from "~/components/joszaki/JoszakiInputOld";
import JoszakiTextAreaOld from "~/components/joszaki/JoszakiTextAreaOld";
import JoszakiDate from "~/components/_refactored/common/JoszakiDate";
import JoszakiTable from "~/components/_refactored/common/JoszakiTable";
import JoszakiTableButton from "~/components/_refactored/common/JoszakiTableButton";
import JoszakiPage from "@/components/_refactored/common/JoszakiPage.vue";
import JoszakiCard from "@/components/_refactored/common/JoszakiCard.vue";
import JoszakiCollapsibleCard from "@/components/_refactored/common/JoszakiCollapsibleCard.vue";
import JoszakiHeading from "@/components/_refactored/common/JoszakiHeading.vue";
import JoszakiUpload from "@/components/_refactored/common/JoszakiUpload.vue";
import JoszakiUploadButton from "@/components/_refactored/common/JoszakiUploadButton.vue";

// GLOBAL registration of components can lead to memory leaks
Vue.component("IconComponent", IconComponent);
Vue.component("JoszakiButton", JoszakiButton);
Vue.component("JoszakiDataTable", JoszakiDataTable);
Vue.component("JoszakiDropdownButton", JoszakiDropdownButton);
Vue.component("JoszakiInput", JoszakiInput);
Vue.component("JoszakiGroupInput", JoszakiGroupInput);
Vue.component("JoszakiGroupButton", JoszakiGroupButton);
Vue.component("JoszakiLoading", JoszakiLoading);
Vue.component("JoszakiCheckbox", JoszakiCheckbox);
Vue.component("JoszakiInputOld", JoszakiInputOld);
Vue.component("JoszakiPill", JoszakiPill);
Vue.component("JoszakiAutocomplete", JoszakiAutocomplete);
Vue.component("JoszakiMessage", JoszakiMessage);
Vue.component("JoszakiTextArea", JoszakiTextArea);
Vue.component("JoszakiTextAreaOld", JoszakiTextAreaOld);
Vue.component("JoszakiDate", JoszakiDate);
Vue.component("JoszakiTable", JoszakiTable);
Vue.component("JoszakiTableButton", JoszakiTableButton);
Vue.component("JoszakiModal", JoszakiModal);
Vue.component("JoszakiPhoneInput", JoszakiPhoneInput);
Vue.component("JoszakiDropdown", JoszakiDropdown);
Vue.component("JoszakiDropdownOld", JoszakiDropdownOld);
Vue.component("JoszakiRadio", JoszakiRadio);
Vue.component("JoszakiSwitch", JoszakiSwitch);
Vue.component("JoszakiPage", JoszakiPage);
Vue.component("JoszakiCard", JoszakiCard);
Vue.component("JoszakiCollapsibleCard", JoszakiCollapsibleCard);
Vue.component("JoszakiHeading", JoszakiHeading);
Vue.component("JoszakiUpload", JoszakiUpload);
Vue.component("JoszakiUploadButton", JoszakiUploadButton);
