import { defineStore } from "pinia";
import CITIES from "@/graphql/cities.graphql";
import PROFESSIONS from "@/graphql/professions.graphql";
import useRestClient from "~/helpers/restClient";

export const useLazyStore = defineStore("lazy", {
  state: () => ({
    cities: [],
    citiesQuery: undefined,
    professions: [],
    professionQuery: undefined,
    countryCodes: [],
    countryCodesQuery: undefined,
  }),
  getters: {
    citiesLoading: (state) => {
      return !!state.citiesQuery;
    },
    citiesLoaded: (state) => {
      return state.cities?.length > 0;
    },
    professionsLoading: (state) => {
      return !!state.professionQuery;
    },
    professionsLoaded: (state) => {
      return state.professions?.length > 0;
    },
    countryCodesLoading: (state) => {
      return !!state.countryCodesQuery;
    },
    countryCodesLoaded: (state) => {
      return state.countryCodes?.length > 0;
    },
  },
  actions: {
    async loadCities() {
      if (this.cities && this.cities.length > 0) {
        return;
      }
      if (this.citiesQuery) {
        await this.citiesQuery;
        return;
      }
      try {
        this.citiesQuery = this.$nuxt.$query(CITIES, {});
        const resp = await this.citiesQuery;
        this.cities = resp.cities;
      } catch (e) {
        console.error("loadCities", e);
      } finally {
        this.citiesQuery = undefined;
      }
    },
    async loadProfessions() {
      if (this.professions && this.professions.length > 0) {
        return;
      }
      if (this.professionQuery) {
        await this.professionQuery;
        return;
      }
      try {
        this.professionQuery = this.$nuxt.$query(PROFESSIONS, {});
        const resp = await this.professionQuery;
        this.professions = resp.professions;
      } catch (e) {
        console.error("loadProfessions", e);
      } finally {
        this.professionQuery = undefined;
      }
    },
    async loadCountryCodes() {
      if (this.countryCodes.length > 0) {
        return;
      }
      if (this.countryCodesQuery) {
        return await this.countryCodesQuery;
      }
      const { phones } = useRestClient(this.$nuxt.$api);
      try {
        this.countryCodesQuery = phones.countryCodes();
        const resp = await this.countryCodesQuery;
        this.countryCodes = resp.data;
        return Promise.resolve(this.countryCodes);
      } catch (e) {
        console.error("loadCountryCodes", e);
      } finally {
        this.countryCodesQuery = undefined;
      }
    },
  },
});
