import weightedRandom from "weighted-random";
import experiments from "~/experiments";
import {
  hasDevVariant,
  validateExperiment,
  isExperimentEnabled,
} from "~/experiments/utils";

const COOKIE_NAME = "exp";

export default function (ctx, inject) {
  /*
   * Serving crawlers, skip test
   */
  if (skipAssignment(ctx) || experiments?.length < 1) {
    inject("exp", {
      ab: () => false,
      mvt: () => false,
      experiments: [],
      getVersion: () => 0,
      reloadExperiments: () => false,
      googleOptimize: () => null,
    });
    return;
  }

  // Assign experiment and variant to user
  const reloadExperiments = () => {
    const enabledExperiments = getEnabledExperiments(experiments);
    const enabledCookieExperiments = parseCookies(ctx, enabledExperiments);
    const cookieExperimentIds = enabledCookieExperiments.map((x) => x.id);
    const experimentsToAdd = enabledExperiments
      .filter((x) => !cookieExperimentIds.includes(x.id) && isEligible(x, ctx))
      .map((x) => initializeExperiment(x));
    // console.info('enabledExperiments', enabledExperiments)
    // console.info('enabledCookieExperiments', enabledCookieExperiments)
    // console.info('addingExperiments', experimentsToAdd)
    // if (experimentsToAdd.length) {
    // console.info('reloading experiments', experimentsToAdd)
    // }
    googleOptimize(
      setExperimentsCookie(ctx, enabledCookieExperiments, experimentsToAdd)
    );
    return [...enabledCookieExperiments, ...experimentsToAdd];
  };

  const activeExperiments = reloadExperiments();

  // Inject $exp
  inject("exp", {
    ab: ab.bind(ctx),
    mvt: mvt.bind(ctx),
    getVersion: getVersion.bind(ctx),
    experiments: activeExperiments,
    reloadExperiments,
  });
}

/**
 * Check whether given variant is what is active for given experient
 *
 * @this {Nuxt} Nuxt context expected to be bound to this using .bind()
 * @param {String} experimentName name of the experiment
 * @param {Number} variant id of variant e.g. 1
 * @returns true if the variant is the assigned one false otherwise
 */
function ab(experimentName, variant) {
  if (!Object.prototype.hasOwnProperty.call(this, "$exp")) {
    throw new Error("Context not bound to getVersion function");
  }
  const version = this.$exp.getVersion(experimentName);
  if (version === null) {
    return false;
  }
  return parseInt(version[0]) === parseInt(variant);
}

/**
 * Check whether given variant of given section is what is active for given experient
 *
 * @this {Nuxt} Nuxt context expected to be bound to this using .bind()
 * @param {String} experimentName name of the experiment
 * @param {Number} section section of the variant, 0 indexed
 * @param {Number} variant id of variant, 0 indexed
 * @returns true if the variant is the assigned one false otherwise
 */
function mvt(experimentName, section, variant) {
  if (!Object.prototype.hasOwnProperty.call(this, "$exp")) {
    throw new Error("Context not bound to getVersion function");
  }
  const version = this.$exp.getVersion(experimentName);
  if (version === null) {
    return false;
  }
  return parseInt(version[section]) === parseInt(variant);
}

/**
 *
 * @this {Nuxt} Nuxt context expected to be bound to this using .bind()
 * @param {Object} experimentName name of the experiment
 * @returns {Number|null} version of the experiment assigned or null if experiment with given experimentName isn't active
 */
function getVersion(experimentName) {
  if (!Object.prototype.hasOwnProperty.call(this, "$exp")) {
    throw new Error("Context not bound to getVersion function");
  }
  for (const experiment of this.$exp.experiments) {
    if (experiment.name !== experimentName) {
      continue;
    }

    if (process.env.NODE_ENV !== "production") {
      if (
        Object.prototype.hasOwnProperty.call(this.route.query, experiment.id)
      ) {
        // Override with query param
        return this.route.query[experiment.id].split("-");
      }

      if (
        Object.prototype.hasOwnProperty.call(this.route.query, experiment.name)
      ) {
        // Override with query param
        return this.route.query[experiment.name].split("-");
      }

      if (hasDevVariant(experiment)) {
        // variant forced by developer
        if (typeof experiment.devVariant === "string") {
          return experiment.devVariant.split("-");
        } else {
          return experiment.devVariant;
        }
      }
    }
    return experiment.variantIndexes;
  }
  return null;
}

/**
 * Validate experiments and filter out the ones that are not enabled for the current environment.
 * Does not check eligiblity!
 *
 * @param {Array} experiments array of experiments
 * @returns {Array} experiments that are enabled
 *
 * @throws if experiment isn't valid
 */
function getEnabledExperiments(experiments) {
  const enabledExperiments = [];
  for (const experiment of experiments) {
    validateExperiment(experiment);
    if (isExperimentEnabled(experiment)) {
      enabledExperiments.push(experiment);
    }
  }
  return enabledExperiments;
}

function isEligible(experiment, ctx) {
  if (Object.prototype.hasOwnProperty.call(experiment, "isEligible")) {
    return experiment.isEligible(ctx);
  }
  return true;
}

function setExperimentsCookie(ctx, expToKeep, expToAdd) {
  const cookieValues = expToKeep.map((x) => x.cookieValue);
  cookieValues.push(...expToAdd.map((x) => x.cookieValue));
  const cookieString = cookieValues.join("!");
  setCookie(ctx, cookieString);
  return cookieString;
}

/**
 * Initializes context for experiments saved in cookies
 *
 * @param {Object} ctx Nuxt Context
 * @param {Array} enabledExperiments
 * @returns {Array} array of context values
 */
function parseCookies(ctx, enabledExperiments) {
  const cookie = getCookie(ctx); // example: id.var1-var2!id2.var1
  if (!cookie || cookie.trim() === "") {
    return [];
  }
  const ctxValues = [];
  try {
    const cookieExperiments = cookie.split("!");
    for (const cookieExperiment of cookieExperiments) {
      const [cookieExp, cookieVars] = cookieExperiment.split(".");
      if (cookieExp && cookieVars) {
        const experiment = enabledExperiments.find(
          (exp) => exp.id === cookieExp
        );
        if (!experiment) {
          continue;
        }
        // Variant indexes, for a/b it's an array with single value
        const variantIndexes = cookieVars.split("-").map((v) => parseInt(v));
        // Validate all indexes are within constrainst
        for (const variantIndex of variantIndexes) {
          if (variantIndex > experiment.variants.length) {
            continue;
          }
        }
        ctxValues.push(toContext(experiment, variantIndexes));
      }
    }
  } catch (err) {
    console.error(err, cookie);
  }
  return ctxValues;
}

/**
 *
 * @param {Object} experiment the experiment
 * @param {Array} variantIndexes array of active indexes, currently one value
 * @returns context object that's available on the nuxt context for given experiment
 */
function toContext(experiment, variantIndexes) {
  const classes = [];
  for (let index = 0; index < experiment.variants.length; index++) {
    classes.push("exp-" + experiment.name + "-" + index);
  }
  return {
    cookieValue: generateCookieValue(experiment, variantIndexes),
    ...experiment,
    classes,
    variantIndexes,
  };
}

/**
 * Assigns variant(s) for the given experiment using weighted random generation
 *
 * @param {Object} experiment
 * @returns {Object} context value of experiment
 */
function initializeExperiment(experiment) {
  const variantIndexes = [];

  // Choose enough variants
  const variantWeights = [...experiment.variants];
  while (variantIndexes.length < (experiment.sections || 1)) {
    const index = weightedRandom(variantWeights);
    variantWeights[index] = 0;
    variantIndexes.push(index);
  }

  return toContext(experiment, variantIndexes);
}

/**
 *
 * @param {Object} experiment
 * @param {Array} variantIndexes
 * @returns {string} string denotation of experiment for the cookie
 */
function generateCookieValue(experiment, variantIndexes) {
  return experiment.id + "." + variantIndexes.join("-");
}

/**
 * Get the cookie that holds the ids and variants denoting the experiments assigned to the user
 *
 * @param {*} ctx Nuxt context
 * @returns {string | null} the value of the cookie or null
 */
function getCookie(ctx) {
  if (process.server && !ctx.req) {
    return null;
  }

  try {
    return ctx.$cookie.get(COOKIE_NAME) || null;
  } catch (err) {
    console.error(err);
    ctx.$sentry.captureException(err, {
      tags: {
        ssr: "optimize",
      },
    });
  }
  return null;
}

/**
 * Sets the exp cookie that is tracking the active AB tests
 *
 * @param {*} ctx Nuxt context
 * @param {string} value value of the cookie

 */
function setCookie(ctx, value) {
  const oldCookie = getCookie(ctx);

  if (oldCookie === value) {
    return;
  }

  let domain = process.env.TOP_DOMAIN.split("//")[1];
  if (domain.includes(":")) {
    domain = domain.split(":")[0];
  }

  ctx.$cookie.set(COOKIE_NAME, value, {
    domain: `.${domain}`,
    path: "/",
    maxAge: 60 * 60 * 24 * 7 * 8 /* 8 weeks */,
  });
}

/**
 * Sends optimize configuration to Google
 *
 * @param {*} cookieValue string representation of active experiments
 */
// https://developers.google.com/optimize/devguides/experiments
function googleOptimize(cookieValue) {
  if (
    process.env.NODE_ENV !== "production" ||
    process.env.DEPLOY_STAGE !== "production"
  ) {
    return;
  }
  if (process.server) {
    return;
  }
  if (!cookieValue) {
    return;
  }

  let interval = null;

  const callback = () => {
    if (window.ga) {
      window.ga("create", "UA-11842832-4", "auto");
      window.ga("set", "exp", cookieValue);
      window.ga("send", "pageview");
      clearInterval(interval);
    }
  };

  interval = setInterval(callback, 1000);
  // ctx.$track.customEvent('exp', getCookie(ctx))
  // ctx.$track.customEvent('config', 'UA-11842832-4', { experiments: activeExperiments.map(x => { return { id: x.id, variant: x.variantIndexes.join('-') } }) })
  // const callBack = () => {
  // for (const experiment of activeExperiments) {
  //   ctx.$track.analyticsEvent({
  //     event: 'Launch experiment',
  //     category: 'Google Optimize',
  //     action: 'Launch experiment',
  //     label: experiment.id,
  //     value: experiment.variantIndexes.join('-')
  //   })
  // }
  // }
  // ctx.$track.customEvent('event', 'optimize.callback', { callback: callBack })
  // window.ga('set', 'exp', ctx.getCookie(COOKIE_NAME))
  // window.ga('send', 'pageview')
}

/**
 * Checks if the visitor is crawler
 *
 * @param {*} ctx Nuxt context
 * @returns true if variant test assignment should be skipped
 */
function skipAssignment(ctx) {
  if (process.server) {
    return (
      ctx.req &&
      ctx.req.headers &&
      ctx.req.headers["user-agent"] &&
      ctx.req.headers["user-agent"].match(/(bot|spider|crawler)/i)
    );
  }

  return navigator.userAgent.match(/(bot|spider|crawler)/i);
}
