export async function useDefaultHead() {
  const brand = await import(`~/branding/${process.env.BRAND}.json`);

  return {
    htmlAttrs: {
      lang: process.env.LOCALE,
    },
    title: brand.name,
    meta: [
      { property: "og:site_name", content: brand.name },
      { property: "og:locale", content: process.env.LOCALE_TERRITORY },
      { name: "author", content: "JóSzaki" },
      { name: "p:domain_verify", content: process.env.PINTEREST_VERIFICATION }, // pinterest
      { name: "theme-color", content: brand.themeColor },
    ],
  };
}
