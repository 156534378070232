<template>
  <div class="flex flex-col gap-4">
    <JoszakiMessage type="info">
      <p>
        {{ $t("personProfile.deleted.notFound") }}
      </p>
      <p>
        {{ $t("personProfile.deleted.subtitle") }}
      </p>
    </JoszakiMessage>
    <OfferOrSearch
      @requestTender="requestTender({ source: 'personNotFound' })"
    />
  </div>
</template>

<script>
import OfferOrSearch from "~/components/mainpage/OfferOrSearch.vue";
import { useRequestTender } from "~/composables/requestTender";

export default {
  components: { OfferOrSearch },
  setup() {
    return {
      ...useRequestTender(),
    };
  },
};
</script>
